import $ from 'jquery';
import 'bootstrap';

import '@/scss/style.scss';

window.jQuery = $;


$('form.find-advisor').each(function () {
  var form = this;
  var zip = $(this).find('input[name="zipCode"]').get(0);

  $(zip).on('input', function (e) {
    if (this.validity.valid) {
      $(form).find('.error-msg').text('');
    }
  });

  $(form).on('submit', function (e) {
    if (!zip.validity.valid) {
      e.preventDefault();
      $(this).addClass('submitted').find('.error-msg').text('Please enter a ZIP code');
    } else {
      $(form).trigger('submit-valid', [e, $(zip).val()]);
    }
  });
});


$('#faq-yourself, #faq-advisors').on('shown.bs.collapse', '.faq', function () {
  $('html,body').animate({
    scrollTop: $(this).offset().top - 54
  }, 500);
});
